export const REQUEST_LOADING = 'REQUEST_LOADING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_GUEST = 'AUTH_GUEST';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';
export const RESTORE_AUTH_STATE = 'RESTORE_AUTH_STATE';
export const LOGOUT = 'LOGOUT';
export const UPDATEFINISH = 'UPDATEFINISH';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CHECKOUT_LOGOUT = 'CHECKOUT_LOGOUT';
