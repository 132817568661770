/* eslint-disable camelcase */
import axios from 'axios';
import Router from 'next/router';
import { destroyCookie, getCookies, setCookie } from '../cookies';
import validateErrors from '../vaildateError';
import validateURLerrors from '../validateURLerrors';

const axiosInstance = axios.create();

// Crear un interceptor para inyectar el token/cartToken en cada llamada
// Indicamos cual es el base url de la API
axiosInstance.interceptors.request.use(
   async (config) => {
      const { token, cartToken, _fbc, _fbp } = getCookies(config.context);
      const headers = {
         ...config.headers,
         Accept: 'application/x-www-form-urlencoded',
         'Content-Type': 'application/json',
      };

      if (token) {
         headers.Authorization = `Bearer ${token}`;
      }

      if (cartToken && !headers['X-Spree-Order-Token']) {
         headers['X-Spree-Order-Token'] = cartToken;
      }

      if (_fbc) {
         headers['X-Spree-Fbc-Token'] = _fbc;
      }

      if (_fbp) {
         headers['X-Spree-Fbp-Token'] = _fbp;
      }

      config.headers = headers;
      config.baseURL = process.env.NEXT_PUBLIC_API_URL;

      return config;
   },
   (error) => {
      Promise.reject(error);
   },
);

// Cuando se produce un error en la llamada verificamos si el token ha expirado
axiosInstance.interceptors.response.use(
   (response) => {
      return response;
   },
   async (error) => {
      const originalRequest = error.config;
      // obtenemos el refresh token guardado
      const { context } = originalRequest;
      const { refreshToken: refresh_token } = getCookies(context);

      if (
         (error.response?.status === 401 || error.response?.status === 403) &&
         refresh_token &&
         !originalRequest?._retry
      ) {
         // hacemos la llamada para obtener un nuevo token
         const response = await axios.post(
            `${process.env.NEXT_PUBLIC_BASE_URL}/spree_oauth/token?code=${process.env.NEXT_PUBLIC_STORE_ID}`,
            {
               grant_type: 'refresh_token',
               refresh_token,
            },
         );

         // guardamos el token nuevo en una cookie
         setCookie('token', response.data.access_token, context);

         // guardar el nuevo refresh token
         setCookie('refreshToken', response.data.refresh_token, context);

         originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;

         // ponemos la propiedad retry en true, para evitar llamadas futuras
         originalRequest._retry = true;

         return axiosInstance({
            url: originalRequest.url,
            method: originalRequest.method,
            headers: originalRequest.headers,
            data: originalRequest.data,
         });
      }

      if ((error.response?.status === 401 || error.response?.status === 403) && !refresh_token) {
         destroyCookie('token');
         Router.push('/mail-login');
      }

      error.message = `${originalRequest?.method}[${error.response?.status || 0}]: ${
         originalRequest?.url
      }`;

      // enviar error a sentry
      const ALLOW_SENTRY = process.env.NEXT_PUBLIC_ALLOW_SENTRY;

      // (no queremos enviar error cuando es el endpoint de landing)
      // y si esta deshabiltado
      if (
         ALLOW_SENTRY === 'true' &&
         validateURLerrors(originalRequest?.url) &&
         validateErrors(error.response?.data.errors[0]?.error_description)
      ) {
         const sentry = await import('../sentry');

         const cookies = getCookies(originalRequest?.context);
         const requestOptions = {
            method: originalRequest?.method,
         };

         if (requestOptions.method !== 'get' && originalRequest?.data) {
            requestOptions.body_request =
               typeof originalRequest.data === 'object'
                  ? originalRequest.data
                  : JSON.parse(originalRequest.data);
         }
         sentry.captureException(error, {
            extra: {
               error_info: !error.response
                  ? 'No hay respuesta del servidor'
                  : error.response?.data.errors?.map((x) => x.error_description).join(' ') || error,
               request_headers: originalRequest.headers,
               cookies,
               ...requestOptions,
            },
            tags: {
               url: originalRequest?.url,
               status_code: error.response?.status || 500,
            },
         });
      }

      return Promise.reject(error);
   },
);

export default axiosInstance;
