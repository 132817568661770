import { SET_THEME } from './actionTypes';

export const setTheme = (name) => async (dispatch, getState) => {
   const { theme } = getState();

   if (theme.name !== name) {
      return dispatch({
         type: SET_THEME,
         name,
      });
   }

   return null;
};

export default setTheme;
