import api from '../../api';
import { MSG_UPDATE_SHIPPING_ADDRESS } from '../../api/error/messages';
import { setNotificationError } from '../toast/toastActions';

import {
   SET_ACTIVE_STEP,
   SET_SHIPPING_TYPE,
   UPDATE_SHIPPING_REQUEST,
   UPDATE_SHIPPING_SUCCESS,
   RESET_SHIPPING_ADDRESS,
   UPDATE_BILLING_REQUEST,
   UPDATE_BILLING_SUCCESS,
   UPDATE_BILLING_REQUEST_SUCCESS,
   RESET_BILLING_ADDRESS,
   RESET_USERS,
   SET_PAYMENT,
   SET_PHONE_NUMBER,
   UPDATE_SHIPPING_REQUEST_SUCCESS,
   SET_PERSONAL_INFO,
   CHANGE_STEP_TITLE_TRUE,
   CHANGE_STEP_TITLE_FALSE,
   SET_CHECKOUT_UNREGISTERED,
   SET_CHECKOUT_REGNOLOGIN,
   SET_EMAIL,
   SET_HAS_PREVIOUS_ORDERS,
   SET_LOGGED_USER,
   SET_LOGGED_USER_PREVIOUS,
   SET_IS_FROM_CHECKOUT,
   RESET_CHECKOUT_STATES,
} from './actionTypes';

export const setActiveStep = (step) => ({
   type: SET_ACTIVE_STEP,
   step,
});

export const setShippingType = (shippingType) => ({
   type: SET_SHIPPING_TYPE,
   shippingType,
});

export const setPersonalInfo = (payload) => ({
   type: SET_PERSONAL_INFO,
   payload,
});

export const setPayment = (payload) => ({
   type: SET_PAYMENT,
   payload,
});

export const setPhoneNumber = (payload) => ({
   type: SET_PHONE_NUMBER,
   payload,
});

export const updateLoadingRequest = () => ({
   type: UPDATE_SHIPPING_REQUEST,
});
export const BillingUpdateLoadingRequest = () => ({
   type: UPDATE_BILLING_REQUEST,
});

export const updateLoadingRequestSuccess = () => ({
   type: UPDATE_SHIPPING_REQUEST_SUCCESS,
});

export const BillingUpdateLoadingRequestSuccess = () => ({
   type: UPDATE_BILLING_REQUEST_SUCCESS,
});

export const updateShippingSuccess = (shippingAddress) => ({
   type: UPDATE_SHIPPING_SUCCESS,
   shippingAddress,
});

export const updateBillingSuccess = (billingAddress) => ({
   type: UPDATE_BILLING_SUCCESS,
   billingAddress,
});

export const getPersonalInfo = (email) => async (dispatch) => {
   const result = await api.checkout.getUserData(email);
   if (result.success) {
      return dispatch(setPersonalInfo(result.data));
   }
   return dispatch(setNotificationError(result.error));
};

// funcion que SOLO se debe ejecutar cuando se modifican datos DESDE FORMULARIO DE ENVIO
export const updateShippingAddress =
   (address, businessSegment, useShipping, type = 'delivery_to_home') =>
   async (dispatch) => {
      dispatch(updateLoadingRequest());
      const deliveryToHome = type === 'delivery_to_home';
      const storePickup = !deliveryToHome;

      const result = await api.checkout.updateShipping({
         order: {
            delivery_to_home: deliveryToHome,
            store_pickup: storePickup,
            use_shipping: useShipping,
            ship_address_attributes: address,
         },
      });

      // if (cartState !== 'payment') {
      //    await dispatch(advanceToNext());
      // }

      if (result.success) {
         return dispatch(
            updateShippingSuccess({
               ...address,
               businessSegment,
               type,
            }),
         );
      }

      return dispatch(setNotificationError(MSG_UPDATE_SHIPPING_ADDRESS));
   };

export const confirmShippingAddress = (
   address,
   businessSegment,
   useShipping,
   type = 'delivery_to_home',
) => {
   return (dispatch) => {
      return dispatch(
         updateShippingSuccess({
            ...address,
            businessSegment,
            type,
         }),
      );
   };
};

export const updateBillingAddress = (address, cartState, businessSegment) => async (dispatch) => {
   dispatch(BillingUpdateLoadingRequest());
   const result = await api.checkout.updateBilling({
      order: {
         use_shipping: false,
         bill_address_attributes: address,
      },
   });

   // if (cartState !== 'payment') {
   //    await dispatch(advanceToNext());
   // }

   if (result.success) {
      return dispatch(
         updateBillingSuccess({
            ...address,
            businessSegment,
            type: 'delivery_to_home',
         }),
      );
   }

   return dispatch(setNotificationError(MSG_UPDATE_SHIPPING_ADDRESS));
};

export const setStoreBranch =
   (id, address, cartState, businessSegment, agency) => async (dispatch) => {
      dispatch(updateLoadingRequest());
      const order = {
         store_branch_id: id,
         delivery_to_home: false,
         store_pickup: true,
      };

      if (agency) {
         order.ship_address_attributes = address;
         order.agency_id = agency;
      }
      const result = await api.checkout.updateShipping({ order });

      if (result.success) {
         return dispatch(
            updateShippingSuccess({
               ...address,
               businessSegment,
               type: 'store_pickup',
            }),
         );
      }

      return dispatch(setNotificationError(MSG_UPDATE_SHIPPING_ADDRESS));
   };

export const resetShippingAddress = () => ({
   type: RESET_SHIPPING_ADDRESS,
});

export const resetBillingAddress = () => ({
   type: RESET_BILLING_ADDRESS,
});
export const resetUsers = () => ({
   type: RESET_USERS,
});
export const changeStepTitleTrue = () => ({
   type: CHANGE_STEP_TITLE_TRUE,
});
export const changeStepTitleFalse = () => ({
   type: CHANGE_STEP_TITLE_FALSE,
});

export const setCheckoutUnregistered = (payload) => ({
   type: SET_CHECKOUT_UNREGISTERED,
   payload,
});
export const setLoggedUser = (payload) => ({
   type: SET_LOGGED_USER,
   payload,
});
export const setLoggedUserPrevious = (payload) => ({
   type: SET_LOGGED_USER_PREVIOUS,
   payload,
});
export const setCheckoutRegNoLogin = (payload) => ({
   type: SET_CHECKOUT_REGNOLOGIN,
   payload,
});
export const setHasPreviousOrders = (payload) => ({
   type: SET_HAS_PREVIOUS_ORDERS,
   payload,
});

export const setEmailCheckout = (payload) => ({
   type: SET_EMAIL,
   payload,
});
export const resetCheckoutStates = (payload) => ({
   type: RESET_CHECKOUT_STATES,
   payload,
});
export const setIsFromCheckout = (payload) => ({
   type: SET_IS_FROM_CHECKOUT,
   payload,
});
