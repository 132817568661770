import auth from './auth';
import banners from './banners';
import branches from './branches';
import brands from './brands';
import cart from './cart';
import checkout from './checkout';
import faqs from './faqs';
import installments from './installments';
import live from './ivs/live';
import landings from './landings';
import mautic from './mautic';
import navbar from './navbar';
import newsletter from './newsletter';
import orders from './orders';
import products from './products';
import resetPassword from './resetPassword';
import review from './review';
import search from './search';
import user from './user';
import userData from './userData';

export default {
   auth,
   navbar,
   products,
   cart,
   banners,
   brands,
   resetPassword,
   user,
   faqs,
   branches,
   installments,
   newsletter,
   search,
   checkout,
   orders,
   landings,
   userData,
   review,
   mautic,
   live,
};
