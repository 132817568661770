import getLastSlug from '../../services/filters/getLastSlug';
import {
   PAGE_BACK,
   PAGE_NEXT,
   SET_PAGE,
   SET_ORDER_BY,
   SET_FILTERS_ACTIVE,
   PAGE_MORE_BACK,
   PAGE_MORE_NEXT,
} from './actionTypes';

export const pageBack = () => (dispatch, getState) => {
   const { current } = getState().products.pagination;
   let page;

   if (current - 1 < 1) {
      page = 1;
   } else {
      page = current - 1;
   }

   dispatch({
      type: PAGE_BACK,
      current: page,
   });
};

export const pageMoreBack = () => (dispatch, getState) => {
   const { current } = getState().products.pagination;
   let page;

   if (current - 1 < 1) {
      page = 1;
   } else {
      page = current - 4;
   }

   dispatch({
      type: PAGE_MORE_BACK,
      current: page,
   });
};

export const pageNext = () => (dispatch, getState) => {
   const { current, total } = getState().products.pagination;
   let page;

   if (current + 1 > total) {
      page = total;
   } else {
      page = current + 1;
   }

   dispatch({
      type: PAGE_NEXT,
      current: page,
   });
};

export const pageMoreNext = () => (dispatch, getState) => {
   const { current, total } = getState().products.pagination;
   let page;

   if (current + 1 > total) {
      page = total;
   } else {
      page = current + 4;
   }

   dispatch({
      type: PAGE_MORE_NEXT,
      current: page,
   });
};

export const setPage = (page) => ({
   type: SET_PAGE,
   page,
});

export const setOrderBy = (orderBy) => ({
   type: SET_ORDER_BY,
   orderBy,
});

export const setFiltersActive = (filtersActive) => ({
   type: SET_FILTERS_ACTIVE,
   filtersActive,
});

export const setFilter =
   (name, value, isArray = false) =>
   async (dispatch, getState) => {
      const { filtersActive } = getState().products;
      return dispatch({
         type: SET_FILTERS_ACTIVE,
         filtersActive: {
            ...filtersActive,
            [name]: isArray ? [...filtersActive[name], value] : value,
            last_slug: value,
         },
      });
   };

export const removeFilter =
   (name, value, isArray = false, label) =>
   async (dispatch, getState) => {
      const { filtersActive } = getState().products;

      const _filtersActive = {
         ...filtersActive,
      };
      if (value === filtersActive.keywords) {
         _filtersActive.keywords = '';
         _filtersActive.last_slug = getLastSlug(_filtersActive);
      } else if (!isArray) {
         delete _filtersActive[name];
      } else {
         const index = _filtersActive[name].indexOf(value);
         _filtersActive[name].splice(index, 1);
      }

      if (value !== filtersActive.keywords) {
         _filtersActive.last_slug = getLastSlug(_filtersActive, name);
      }
      return dispatch({
         type: SET_FILTERS_ACTIVE,
         filtersActive: _filtersActive,
      });
   };
