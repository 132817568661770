import axios from '../../services/axiosInstance';
import { getCookies, setCookie } from '../../services/cookies';
import handleCartError from '../../services/handleCartError';

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

// TODO: Ver compatibilidad de la librería nookies en navegadores antiguos
const create = async () => {
   try {
      // hacemos la llamada para crear un carrito
      const response = await axios.post(`/cart?code=${storeId}`);
      const cartToken = response.data.data.attributes.token;

      // guardamos el token del carrito en cookie
      setCookie('cartToken', cartToken);

      // retornamos el carrito
      return {
         success: true,
         data: response.data.data.attributes,
      };
   } catch (err) {
      return handleCartError(err);
   }
};

const get = async () => {
   try {
      // si ya existe el carrito
      // lo obtenemos nuevamente del servidor actualizado
      const response = await axios.get(`/cart?code=${storeId}`);

      // retornamos el carrito actualizado
      return {
         success: true,
         data: response.data.data.attributes,
      };
   } catch (err) {
      return handleCartError(err);
   }
};

const addItem = async (variantId, quantity, eventId, tryRequest = 0) => {
   try {
      // verificamos si tiene cartToken
      const { cartToken } = getCookies();
      if (!cartToken) await create();

      // agregamos el producto al carrito
      const response = await axios.post('/cart/add_item', {
         quantity,
         code: storeId,
         variant_id: variantId,
         event_id: eventId,
      });
      // retornamos el carrito actualizado
      return {
         success: true,
         data: response.data.data.attributes,
      };
   } catch (err) {
      return handleCartError(err, create, addItem, [variantId, quantity, eventId], tryRequest);
   }
};

const setQuantityItem = async (lineItemId, quantity) => {
   try {
      // agregamos el producto al carrito
      const response = await axios.patch('/cart/set_quantity', {
         line_item_id: lineItemId,
         code: storeId,
         quantity,
      });

      // retornamos el carrito actualizado
      return {
         success: true,
         data: response.data.data.attributes,
      };
   } catch (err) {
      return handleCartError(err, create, setQuantityItem, [lineItemId, quantity]);
   }
};

const removeItem = async (lineItemId) => {
   try {
      // agregamos el producto al carrito
      const response = await axios.delete(`/cart/remove_line_item/${lineItemId}?code=${storeId}`);

      // retornamos el carrito actualizado
      return {
         success: true,
         data: response.data.data.attributes,
      };
   } catch (err) {
      return handleCartError(err, create, removeItem, [lineItemId]);
   }
};

const setCuponCode = async (code) => {
   try {
      // enviamos la promo al back
      const response = await axios.patch('/cart/apply_coupon_code', {
         coupon_code: code,
         code: storeId,
      });
      // retornamos el carrito con la promo
      return {
         success: true,
         data: response.data.data.attributes,
      };
   } catch (err) {
      return handleCartError(err, create, setCuponCode, [code]);
   }
};

const finishCart = async () => {
   // reseteamos el estado del reducer
   return {
      success: true,
      data: {
         token: null,
         line_items: [],
         item_count: 0,
         total: 0,
         showModal: false,
         loading: false,
         errors: null,
      },
   };
};

export default {
   create,
   get,
   addItem,
   setQuantityItem,
   removeItem,
   setCuponCode,
   finishCart,
};
