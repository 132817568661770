import { useEffect } from 'react';

export default function useDeleteTagDuplicate(name, time, tag) {
   const removeMetaElement = (name) => {
      const removeElement = (arr) => {
         arr[0].remove();
         arr.shift();
         arr.length > 1 && removeElement(arr);
      };
      const meta = document?.getElementsByTagName(tag);
      const metaTags = [];
      for (let i = 0; i < meta.length; i++) {
         if (meta[i].rel === name || meta[i].name === name || meta[i].outerHTML === name) {
            metaTags.push(meta[i]);
         }
      }
      if (metaTags.length > 1) removeElement(metaTags);
   };
   useEffect(() => {
      // Se espera un tiempo, ya que nicialmente no están duplicadas
      setTimeout(() => {
         removeMetaElement(name);
      }, time);
   }, []);
}
