import eventTracking from '../eventTracking';

// Agregar evento a la cola, lo guardamos en el storage
const addToQueue = (name, options) => {
   const eventsQueue = JSON.parse(localStorage.getItem('eventsQueue')) || [];
   eventsQueue.push({ name, options });
   localStorage.setItem('eventsQueue', JSON.stringify(eventsQueue));
};

// Al finalizar de ejecutar la cola, limpiamos el storage
const clearQueue = () => {
   localStorage.removeItem('eventsQueue');
};

// Ejecutamos todos los eventos de la cola
const runQueue = () => {
   const eventsQueue = JSON.parse(localStorage.getItem('eventsQueue')) || [];

   eventsQueue.forEach((event) => {
      eventTracking[event.name](event.options);
   });

   clearQueue();
};

// Recibe un evento, y se agrega a la cola si el tracking no esta listo
// Si esta listo el tracking, se ejecuta directamente
const runEvent = (name, options, trackingReady) => {
   // verificamos si analytics-pixel estan listo
   if (trackingReady) {
      // ejecutamos el evento
      // ej: eventTracking.pageview('/login')
      eventTracking[name](options);
   } else if (process.env.NEXT_PUBLIC_ALLOW_ROBOTS === 'true') {
      // si no estan listo, agregamos el evento a la cola
      addToQueue(name, options);
   }
};

export { runEvent, runQueue, addToQueue, clearQueue };
