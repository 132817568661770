import API from '../../api';
import {
   FETCH_PRODUCT_REQUEST,
   FETCH_PRODUCT_SUCCESS,
   FETCH_PRODUCT_FAILURE,
   SET_COLOR,
   SET_VARIANT,
   SET_QUANTITY,
} from './actionTypes';

export const setColor = (color) => ({
   type: SET_COLOR,
   color,
});

export const setVariant = (variant) => ({
   type: SET_VARIANT,
   variant,
});

export const setQuantity = (quantity) => ({
   type: SET_QUANTITY,
   quantity,
});

export const fetchProductRequest = () => ({
   type: FETCH_PRODUCT_REQUEST,
});

export const fetchProductSuccess = ({ product, colorSelected }) => ({
   type: FETCH_PRODUCT_SUCCESS,
   product,
   colorSelected,
});

export const fetchProductFailure = (error) => ({
   type: FETCH_PRODUCT_FAILURE,
   error,
});

export const fetchProduct = (id) => async (dispatch, getState) => {
   dispatch(fetchProductRequest());

   const result = await API.products.getByID(id);

   if (result.success) {
      const colorId = getState().productDetail.colorSelected.id;
      const colorSelected = result.data.colors.find(
         (c) => c.id === Number(colorId),
      );

      dispatch(
         fetchProductSuccess({
            product: result.data,
            colorSelected,
         }),
      );
   } else {
      dispatch(fetchProductFailure(result.error));
   }
};
