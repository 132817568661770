/* eslint-disable camelcase */
export default function getLastSlug(filtersActive, filterRemoved) {
   let last_slug = '';

   const { talle, slugs, genero, keywords } = filtersActive;

   if (talle?.length > 0) {
      last_slug = talle[talle.length - 1];
   } else if (slugs?.length > 0) {
      last_slug = slugs[slugs.length - 1];
   } else if (genero) {
      last_slug = genero;
   } else if (keywords) {
      last_slug = keywords;
   }

   return last_slug;
}
