const provinces = [
   { id: 108, name: 'Mendoza' },
   { id: 3803, name: 'Salta' },
   { id: 3814, name: 'Buenos Aires' },
   { id: 3815, name: 'Ciudad Autónoma de Buenos Aires' },
   { id: 3795, name: 'San Luis' },
   { id: 3799, name: 'Entre Rios' },
   { id: 3818, name: 'Santiago del Estero' },
   { id: 3819, name: 'Chaco' },
   { id: 3794, name: 'San Juan ' },
   { id: 3804, name: 'Catamarca' },
   { id: 3797, name: 'Cordoba' },
   { id: 3809, name: 'Corrientes' },
   { id: 3811, name: 'La Pampa' },
   { id: 3810, name: 'Misiones' },
   { id: 3825, name: 'Formosa' },
   { id: 3800, name: 'Neuquen' },
   { id: 3801, name: 'Rio Negro' },
   { id: 3806, name: 'Santa Fe' },
   { id: 3808, name: 'Tucuman' },
   { id: 3796, name: 'Chubut' },
   { id: 3812, name: 'Tierra del Fuego' },
   { id: 3802, name: 'Jujuy' },
   { id: 3805, name: 'Santa Cruz' },
   { id: 3798, name: 'La Rioja' },
];

const getProvinceById = (provinceId) => {
   const province = provinces.find((provincie) => provincie.id === parseInt(provinceId, 10));
   return province?.name;
};

export { provinces, getProvinceById };
