import React from 'react';
import Spinner from 'Spinner';
import cn from 'classnames';
import PropTypes from 'prop-types';
import stringWithoutSpaces from '../../services/PropTypes/stringWithoutSpaces';
import styles from './button.module.scss';

function Button(props) {
   const {
      id,
      label,
      normal,
      className,
      outlined,
      text,
      variant,
      onClick,
      icon,
      // TODO: Considerar si es necesario el iconRight prop
      iconRight,
      iconBadge,
      disabled,
      loading,
      additionalStyles,
   } = props;

   const buttonClass = cn({
      [styles.base]: true,
      [className]: !!className,
      [styles[variant]]: true,
      [styles.outlined]: outlined,
      [styles.text]: text,
      [styles.iconRight]: iconRight,
      [styles.disabled]: disabled,
   });

   return (
      <button
         type={normal ? 'button' : 'submit'}
         className={buttonClass}
         onClick={disabled ? null : onClick}
         style={additionalStyles}
         id={id}
      >
         {icon && (
            <span className={styles.icon}>
               {icon}
               <span className={styles.badge}>{iconBadge}</span>
            </span>
         )}

         {label}

         {loading && <Spinner className={styles.loader} />}
      </button>
   );
}

Button.propTypes = {
   label: PropTypes.string.isRequired,
   normal: PropTypes.bool,
   outlined: PropTypes.bool,
   text: PropTypes.bool,
   className: stringWithoutSpaces,
   variant: PropTypes.oneOf([
      'primary',
      'secondary',
      'success',
      'danger',
      'edit',
      'cancel',
      'back',
      'warning',
   ]),
   icon: PropTypes.element,
   iconRight: PropTypes.bool,
   iconBadge: PropTypes.number,
   onClick: PropTypes.func,
   disabled: PropTypes.bool,
   loading: PropTypes.bool,
};

Button.defaultProps = {
   normal: false,
   outlined: false,
   text: false,
   className: null,
   variant: 'primary',
   icon: null,
   iconRight: false,
   iconBadge: null,
   onClick: null,
   disabled: false,
   loading: false,
};

export default Button;
