const themes = {
   vaypol: {
      'color-primary-pag': '#ffd100',
      'color-secondary-pag': '#000000',
      'color-primary': '#ffd100',
      'color-primary-light': '#ffe157',
      'color-primary-contrast': '#000000',
      'color-secondary': '#000000',
      'color-secondary-light': '#ffffff',
      'color-secondary-contrast': '#ffd100',
      'color-header-bg': '#000000',
      'color-logo-bg': '#000',
      'color-background': '#ffffff',
      'color-checkout-section': '#000000',
      'color-text': '#000000',
      'color-title': 'rgba(51, 51, 51, 0.8)',
      'color-button': '#ffd100',
      'color-button-text-primary': '#000000',
      'color-button-text-secondary': '#000000',
      'color-button-text-outlined': '#ffffff',
      'color-button-text-hover': '#000000',
      'color-text-newsletter': '#000000',
      'background-newsletter': '#ffd100',
      'caption-color-newsletter': '#000000',
      'color-warning-botton': '#ffd100',
      'color-warning-text': '#000000',
      'color-button-newsletter': 'var(--color-primary)',
      'background-button-newsletter': 'var(--color-primary-contrast)',
      'color-cart-bg': '#000000',
      'color-cart-border': '#000000',
      'color-cart-text': '#ffffff',
      'color-prod-detail-badge-bg-principal': 'rgb(51, 51, 51)',
      'color-prod-detail-badge-text-principal': 'rgb(255, 209, 0',
      'color-prod-detail-badge-bg': '#ffd100',
      'color-prod-detail-badge-text': '#333333',
      'color-prod-detail-badge-bg-ship': '#336535',
      'color-prod-detail-badge-text-ship': '#ffffff',
      'color-spinner-btn': '0,0,0',
      'color-spinner-fill': '255,255,255',
      'color-spinner-data': '0,0,0',
   },
   city: {
      'color-primary-pag': '#00b1d3',
      'color-secondary-pag': '#b02460',
      'color-primary': '#00b1d3',
      'color-primary-light': '#000000',
      'color-primary-contrast': '#ffffff',
      'color-secondary': '#b02460',
      'color-secondary-light': '#ffffff',
      'color-secondary-contrast': '#ffffff',
      'color-header-bg': '#B02460',
      'color-logo-bg': '#B02460',
      'color-background': '#000000',
      'color-checkout-section': '#000000',
      'color-text': '#ffffff',
      'color-title': '#00b1d3',
      'color-button': '#00b1d3',
      'color-button-text-primary': '#ffffff',
      'color-button-text-secondary': '#ffffff',
      'color-button-text-outlined': '#ffffff',
      'color-button-text-hover': '#ffffff',
      'color-warning-botton': '#00b1d3',
      'color-warning-text': '#000000',
      'caption-color-newsletter': '#ffffff',
      'color-text-newsletter': '#ffffff',
      'background-newsletter': '#00b1d3',
      'background-button-newsletter': 'var(--color-secondary)',
      'color-button-newsletter': 'var(--color-secondary-contrast)',
      'color-cart-bg': '#000000',
      'color-cart-border': '#000000',
      'color-cart-text': '#ffffff',
      'color-prod-detail-badge-bg-principal': 'rgb(51, 51, 51)',
      'color-prod-detail-badge-text-principal': 'rgb(255, 209, 0',
      'color-prod-detail-badge-bg': '#ffd100',
      'color-prod-detail-badge-text': '#333333',
      'color-prod-detail-badge-bg-ship': '#336535',
      'color-prod-detail-badge-text-ship': '#ffffff',
      'color-spinner-btn': '255,255,255',
      'color-spinner-fill': '255,255,255',
      'color-spinner-data': '0,0,0',
   },
   acsm: {
      'color-primary-pag': '#ffffff',
      'color-secondary-pag': '#E31E25',
      'color-primary': '#E31E25',
      'color-primary-light': '#000000',
      'color-primary-contrast': '#ffffff',
      'color-secondary': '#E31E25',
      'color-secondary-light': '#ffffff',
      'color-secondary-contrast': '#ffffff',
      // 'color-header-bg': '#E31E25',
      'color-header-bg': '#ffffff',
      'color-logo-bg': '#000',
      'color-background': '#000000',
      'color-checkout-section': '#ffffff',
      'color-text': '#ffffff',
      'color-title': '#ffffff',
      'color-button': '#E31E25',
      'color-button-text-primary': '#ffffff',
      'color-button-text-secondary': '#000000',
      'color-button-text-outlined': '#000000',
      'color-button-text-hover': '#ffffff',
      'color-warning-botton': '#000000',
      'color-warning-text': '#d8d6d6',
      'color-text-newsletter': '#ffffff',
      'caption-color-newsletter': '#ffffff',
      'background-button-newsletter': 'var(--color-secondary)',
      'background-newsletter': '#000000',
      'color-button-newsletter': 'var(--color-secondary-contrast)',
      'color-cart-bg': '#ffffff',
      'color-cart-border': '#E31E25',
      'color-cart-text': '#000000',
      'color-prod-detail-badge-bg-principal': '#000',
      'color-prod-detail-badge-text-principal': '#fff',
      'color-prod-detail-badge-bg': '#E31E25',
      'color-prod-detail-badge-text': '#ffffff',
      'color-prod-detail-badge-bg-ship': '#E31E25',
      'color-prod-detail-badge-text-ship': '#ffffff',
      'color-spinner-btn': '255,255,255',
      'color-spinner-fill': '255,255,255',
      'color-spinner-data': '0,0,0',
   },
   gye: {
      'color-primary-pag': '#ffffff',
      'color-secondary-pag': '#0c0c0c',
      'color-primary': '#0c0c0c',
      'color-primary-light': '#0c0c0c',
      'color-primary-contrast': '#ffffff',
      'color-secondary': '#0c0c0c',
      'color-secondary-light': '#ffffff',
      'color-secondary-contrast': '#ffffff',
      // 'color-header-bg': '#0c0c0c',
      'color-header-bg': '#ffffff',
      'color-background': '#0c0c0c',
      'color-checkout-section': '#ffffff',
      'color-text': '#ffffff',
      'color-title': '#ffffff',
      'color-button': '#0c0c0c',
      'color-button-text-primary': '#ffffff',
      'color-button-text-secondary': '#000000',
      'color-button-text-hover': '#ffffff',
      'color-warning-botton': '#0c0c0c',
      'color-warning-text': '#d8d6d6',
      'caption-color-newsletter': '#ffffff',
      'background-button-newsletter': 'var(--color-secondary)',
      'color-text-newsletter': '#000000',
      'background-newsletter': '#f5f5f5',
      'color-button-newsletter': 'var(--color-secondary-contrast)',
      'color-cart-bg': '#ffffff',
      'color-cart-border': '#0c0c0c',
      'color-cart-text': '#0c0c0c',
      'color-prod-detail-badge-bg-principal': '#0c0c0c',
      'color-prod-detail-badge-text-principal': '#fff',
      'color-prod-detail-badge-bg': '#0c0c0c',
      'color-prod-detail-badge-text': '#ffffff',
      'color-prod-detail-badge-bg-ship': '#0c0c0c',
      'color-prod-detail-badge-text-ship': '#ffffff',
      'color-spinner-btn': '255,255,255',
      'color-spinner-fill': '255,255,255',
      'color-spinner-data': '0,0,0',
   },

   generic: {
      'color-primary': process.env.NEXT_PUBLIC_COLOR_PRIMARY,
      'color-primary-contrast': process.env.NEXT_PUBLIC_COLOR_PRIMARY_CONTRAST,
      'color-header-bg': process.env.NEXT_PUBLIC_COLOR_HEADER_BG,
   },
};

export default themes;
