import parsePriceToNumber from '../parsePriceToNumber';

/* eslint-disable camelcase */
export default function normalizeProduct(productDetail) {
   const { product, id, variantSelected, colorSelected, quantity } = productDetail;
   const { sale_price, discount } = colorSelected.prices;

   let price;

   if (sale_price && discount) {
      price = colorSelected.prices.sale_price;
   } else {
      price = colorSelected.prices.original;
   }

   return {
      id: product.sku,
      name: product.name,
      price: parsePriceToNumber(price),
      brand: product.brand_name,
      category: product.category_name,
      variant: variantSelected?.size || null,
      quantity,
   };
}
