const invalidErrors = [
   // array de errores que no queremos enviar a sentry
   'Tarjeta Denegada. Comunicate telefónicamente con el emisor de la tarjeta para autorizar la compra.',
   'Compra rechazada. Algunos de los datos de tu tarjeta ingresados no son correctos, por favor ingresá los datos nuevamente, si este error sigue llamanos a nuestro Centro de Atención 0810 666 0945',
   'Compra rechazada. El sistema tiene un error momentáneo. Reintenta nuevamente en unos momentos o llamanos a nuestro Centro de Atención 0810 666 0945 para ayudarte a terminar tu compra.',
   'Fondos Insuficientes. Intentá con otras cuotas o cambia de tarjeta. Recordá que las Tarjetas NO emitidas por Entidades Bancarias solo pueden realizar la compra en 1 Cuota.',
   'Tarjeta Denegada. Intentá con otras cuotas o cambia de tarjeta. Recordá que las Tarjetas NO emitidas por Entidades Bancarias solo pueden realizar la compra en 1 Cuota.',
   'Solicitá autorización telefónicamente con el emisor de la tarjeta para realizar la compra o intentá con otra tarjeta.',
   'Compra rechazada. Intentá con otras cuotas o cambia de tarjeta. Recordá que las Tarjetas NO emitidas por Entidades Bancarias solo pueden realizar la compra en 1 Cuota. Llamanos al Centro de Atención 0810 666 0945 para ayudarte a terminar tu compra.',
   'Tarjeta NO HABILITADA. Comunicate telefónicamente con el emisor de la tarjeta para realizar la compra.',
   'Tarjeta Denegada. Revisa que tu tarjeta este vigente en la fecha de vencimiento o prueba con otra tarjeta.',
   'Tarjeta Denegada. Intentá con otras cuotas o cambia de tarjeta. Recordá que las Tarjetas NO emitidas por Entidades Bancarias solo pueden realizar la compra en 1 Cuota.',
   'Ingrese un número de tarjeta válido.',
   'Ingrese una fecha de expiración válida.',
   '¡Ya existe una cuenta asociada a este correo!',
   'El código del cupón que has introducido no existe. Inténtalo de nuevo.',
   'Correo Electrónico no es válido',
   'Correo No fue encontrado',
   'Pagina No Encontrada',
   '¡Ya se realizaron compras con este correo! En unos minutos recibirás un correo electrónico con instrucciones sobre cómo recuperar tu cuenta.',
];

const validateErrors = (error) => {
   if (invalidErrors.includes(error)) return false;

   return true;
};

export default validateErrors;
