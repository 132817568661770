/* eslint-disable import/prefer-default-export */
import { runQueue } from '../../services/queue';
import { SET_TRACKING_READY } from './actionTypes';

export const setTrackingReady = (tracker) => async (dispatch, getState) => {
   const { analytics, pixel } = getState().tracking;

   dispatch({
      type: SET_TRACKING_READY,
      payload: {
         ready: !!(analytics || pixel),
         [tracker]: true,
      },
   });

   if (analytics || pixel) {
      runQueue();
   }
};
