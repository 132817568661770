import axios from '../../services/axiosInstance';
import errorHandler from '../error/handler';
import { MSG_LOGIN, MSG_RESET_PASSWORD } from '../error/messages';

const forgotPassword = async (email, code) => {
   try {
      const response = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/recover_password`, {
         email,
         code,
      });
      return {
         success: true,
         data: response,
      };
   } catch (err) {
      const message = errorHandler(err, MSG_LOGIN);
      return {
         success: false,
         error: message,
      };
   }
};
const resetPassword = async (passwordToken, password, passwordConfirmation) => {
   try {
      const response = await axios.put(`${process.env.NEXT_PUBLIC_API_URL}/recover_password`, {
         reset_password_token: passwordToken,
         password,
         password_confirmation: passwordConfirmation,
      });
      return {
         success: true,
         data: response.data,
      };
   } catch (err) {
      const message = errorHandler(err, MSG_RESET_PASSWORD);
      return {
         success: false,
         error: message,
      };
   }
};

const sendAuthCode = async (email) => {
   try {
      const response = await axios.post('/account/send_auth_code', {
         email,
      });
      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const errors = errorHandler(err);
      return {
         success: false,
         errors,
      };
   }
};

export default {
   forgotPassword,
   resetPassword,
   sendAuthCode,
};
