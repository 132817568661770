export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';
export const FETCH_CART_DELETE = 'FETCH_CART_DELETE';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';

export const SET_QUANTITY_CART_SUCCESS = 'SET_QUANTITY_CART_SUCCESS';
export const SET_QUANTITY_CART_FAILURE = 'SET_QUANTITY_CART_FAILURE';

export const SET_CUPON_CODE_SUCCESS = 'SET_CUPON_CODE_SUCCESS';
export const SET_CUPON_CODE_FAILURE = 'SET_CUPON_CODE_FAILURE';

export const REMOVE_ITEM_CART_SUCCESS = 'REMOVE_ITEM_CART_SUCCESS';
export const REMOVE_ITEM_CART_FAILURE = 'REMOVE_ITEM_CART_FAILURE';

export const SHOW_CHANGE_MODAL_CART = 'SHOW_CHANGE_MODAL_CART';
export const HIDE_CHANGE_MODAL_CART = 'HIDE_CHANGE_MODAL_CART';

export const SHOW_MODAL_CART = 'SHOW_MODAL_CART';
export const HIDE_MODAL_CART = 'HIDE_MODAL_CART';

export const RESET_PAYMENT = 'RESET_PAYMENT';

export const ADVANCE_TO_NEXT_REQUEST = 'ADVANCE_TO_NEXT_REQUEST';
export const ADVANCE_TO_NEXT_SUCCESS = 'ADVANCE_TO_NEXT_SUCCESS';
export const ADVANCE_TO_NEXT_FAILURE = 'ADVANCE_TO_NEXT_FAILURE';
export const DELETE_CART = 'DELETE_CART';
