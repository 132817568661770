import axios from '../../services/axiosInstance';
import errorHandler from '../error/handler';

const get = async (storeId) => {
   try {
      const response = await axios.get(`/sucursales?code=${storeId}`);

      let patch;

      if (Object.keys(response.data.data).length === 0) {
         patch = {
            'San Martín': [
               {
                  id: '10',
                  office_hours: 'Lunes a sábado de 9 a 13 hs. y 16:30 a 20:30 hs.',
                  active: 'true',
                  store_pickup: 'true',
                  latitude: '-33.083141',
                  longitude: '-68.474778',
                  business_segment: 'VAYPOL',
                  image_store_branch: '',
                  address: {
                     address1: 'Boulogne Sur Mer',
                     address2: 'Esquina Nogues',
                     zipcode: '5570',
                     phone: '4427845',
                     address_number: '',
                  },
               },
            ],
         };
      }

      return {
         success: true,
         data: patch || response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const checkout = async (storeId, stateId = 108, zipCode = 5500) => {
   try {
      const response = await axios.get(
         `/sucursales/pickup_store_branches?code=${storeId}&state=${stateId}&zip_code=${zipCode}`,
      );

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

export default {
   get,
   checkout,
};
