import {
   SET_NOTIFICATION_INFO,
   SET_NOTIFICATION_ERROR,
   SET_NOTIFICATION_SUCCESS,
} from './actionTypes';

export const setNotificationInfo = (message) => ({
   type: SET_NOTIFICATION_INFO,
   message,
});

export const setNotificationError = (message) => ({
   type: SET_NOTIFICATION_ERROR,
   message,
});
export const setNotificationSuccess = (message) => ({
   type: SET_NOTIFICATION_SUCCESS,
   message,
});
