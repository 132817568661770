import Cookie from 'js-cookie';
import API from '../../api';
import { fetchCart } from '../cart/cartActions';
import {
   REQUEST_LOADING,
   AUTH_SUCCESS,
   AUTH_GUEST,
   EMAIL_VERIFICATION_SUCCESS,
   REQUEST_FAILURE,
   RESTORE_AUTH_STATE,
   CLEAR_ERROR,
   LOGOUT,
   CHECKOUT_LOGOUT,
   UPDATEFINISH,
} from './actionTypes';

export const requestLoading = () => ({
   type: REQUEST_LOADING,
});

export const authSuccess = (user) => ({
   type: AUTH_SUCCESS,
   user,
});
export const authGuest = (user) => ({
   type: AUTH_GUEST,
   user,
});

export const emailVerificationSuccess = () => ({
   type: EMAIL_VERIFICATION_SUCCESS,
});

export const requestFailure = (errors) => ({
   type: REQUEST_FAILURE,
   errors,
});

export const clearError = () => ({
   type: CLEAR_ERROR,
});

export const logout = (finish) => async (dispatch) => {
   await API.auth.logout();
   // await API.cart.create();

   dispatch({
      type: LOGOUT,
      payload: finish,
   });
};

export const updateFinish = (finish) => async (dispatch) => {
   dispatch({
      type: UPDATEFINISH,
      payload: finish,
   });
};

export const checkoutLogout = () => async (dispatch) => {
   await API.auth.checkoutLogout();

   dispatch({
      type: CHECKOUT_LOGOUT,
   });
};

export const login = (email, password) => async (dispatch) => {
   dispatch(requestLoading());

   const result = await API.auth.login(email, password);

   if (result.success) {
      await dispatch(fetchCart());
      return dispatch(authSuccess(result.data));
   }

   return dispatch(requestFailure(result.errors));
};
export const receiveAuthCode = (email, authCode) => async (dispatch) => {
   dispatch(requestLoading());

   const result = await API.auth.receiveAuthCode(email, authCode);

   if (result.success) {
      await dispatch(fetchCart());
      return dispatch(authSuccess(result.data));
   }

   return dispatch(requestFailure(result.errors));
};

export const signup = (user, eventId) => async (dispatch) => {
   dispatch(requestLoading());

   const result = await API.auth.signup(user, eventId);

   if (result.success) {
      await dispatch(fetchCart());
      return dispatch(authSuccess(result.data));
   }

   return dispatch(requestFailure(result.errors));
};

export const emailValidation = (email, finisher) => async (dispatch) => {
   dispatch(requestLoading());

   const result = await API.auth.validateEmail(email, finisher);

   if (result.success) {
      return dispatch(emailVerificationSuccess());
   }

   return dispatch(requestFailure(result.errors));
};

export const guestEmailValidation = (email) => async (dispatch) => {
   dispatch(requestLoading());

   const result = await API.auth.guestEmail(email);

   if (result.success) {
      return dispatch(emailVerificationSuccess());
   }

   return dispatch(requestFailure(result.errors));
};

export const restoreAuthState = () => (dispatch) => {
   // obtenemos el objeto user y el token
   const user = JSON.parse(localStorage.getItem('user'));
   const token = Cookie.get('token');

   // si hay token pero no hay user el token es residual, hay que borrarlo
   if (!!token && !user) {
      Cookie.remove('token');
   }

   dispatch({
      type: RESTORE_AUTH_STATE,
      // si el token existe, esta logueado
      isLoggedIn: !!token && !!user,
      // user puede ser null si nunca ha iniciado sesion
      user,
   });
};
