import axios from '../../services/axiosInstance';
import errorHandler from '../error/handler';

const get = async (filtros, page, storeId = process.env.NEXT_PUBLIC_STORE_ID, headers = {}) => {
   try {
      const response = await axios.post(
         `/products?code=${storeId}`,
         {
            page,
            code: storeId,
            filtros,
            keywords: filtros.keywords,
         },
         { headers },
      );

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const getByID = async (id, eventId, storeId = process.env.NEXT_PUBLIC_STORE_ID) => {
   try {
      const response = await axios.get(`/products/${id}?event_id=${eventId}&code=${storeId}`);

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const getWearItWith = async (productId, storeId) => {
   try {
      const response = await axios.get(`/products/${productId}/wear_it_with?code=${storeId}`);

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};
const getCustomersAlsoViewed = async (productId, storeId) => {
   try {
      const response = await axios.get(
         `/products/${productId}/customers_also_viewed?code=${storeId}`,
      );

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const getPromotedProducts = async (storeId) => {
   try {
      const response = await axios.get(`/products/promotions_products?code=${storeId}`);

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const getNewnessProducts = async (storeId) => {
   try {
      const response = await axios.get(`/products/newness_products?code=${storeId}`);

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const validateSlug = async (storeId, slug = '') => {
   try {
      const response = await axios.post('/products/validate_slug', {
         code: storeId,
         slug,
      });
      return response.data;
   } catch (err) {
      const errors = errorHandler(err);

      return {
         success: false,
         errors,
      };
   }
};

const sendFbEvent = async (type, options, eventId) => {
   try {
      const response = await axios.post('/events', {
         type,
         custom_data: options,
         event_id: eventId,
      });
      return response.data;
   } catch (err) {
      const errors = errorHandler(err);

      return {
         success: false,
         errors,
      };
   }
};

export default {
   get,
   getByID,
   getPromotedProducts,
   getCustomersAlsoViewed,
   getWearItWith,
   getNewnessProducts,
   validateSlug,
   sendFbEvent,
};
