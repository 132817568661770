export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_SHIPPING_TYPE = 'SET_SHIPPING_TYPE';
export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_CHECKOUT_UNREGISTERED = 'SET_CHECKOUT_UNREGISTERED';
export const SET_CHECKOUT_REGNOLOGIN = 'SET_CHECKOUT_REGNOLOGIN';
export const SET_HAS_PREVIOUS_ORDERS = 'SET_HAS_PREVIOUS_ORDERS';
export const SET_LOGGED_USER = 'SET_LOGGED_USER';
export const SET_LOGGED_USER_PREVIOUS = 'SET_LOGGED_USER_PREVIOUS';
export const SET_EMAIL = 'SET_EMAIL';
export const RESET_CHECKOUT_STATES = 'RESET_CHECKOUT_STATES';
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO';
export const SET_IS_FROM_CHECKOUT = 'SET_IS_FROM_CHECKOUT';

export const UPDATE_SHIPPING_REQUEST = 'UPDATE_SHIPPING_REQUEST';
export const UPDATE_SHIPPING_REQUEST_SUCCESS = 'UPDATE_SHIPPING_REQUEST_SUCCESS';
export const UPDATE_SHIPPING_SUCCESS = 'UPDATE_SHIPPING_SUCCESS';
export const RESET_SHIPPING_ADDRESS = 'RESET_SHIPPING_ADDRESS';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const RESET_USERS = 'RESET_USERS';

export const UPDATE_BILLING_REQUEST = 'UPDATE_BILLING_REQUEST';
export const UPDATE_BILLING_REQUEST_SUCCESS = 'UPDATE_BILLING_REQUEST_SUCCESS';
export const UPDATE_BILLING_SUCCESS = 'UPDATE_BILLING_SUCCESS';
export const RESET_BILLING_ADDRESS = 'RESET_BILLING_ADDRESS';
export const CHANGE_STEP_TITLE_TRUE = 'CHANGE_STEP_TITLE_TRUE';
export const CHANGE_STEP_TITLE_FALSE = 'CHANGE_STEP_TITLE_FALSE';
