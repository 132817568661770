import axios from '../../services/axiosInstance';
import errorHandler from '../error/handler';

const get = async (context) => {
   try {
      const response = await axios.get('/account/profile', {
         context,
      });

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const errors = errorHandler(err);

      return {
         success: false,
         errors,
      };
   }
};

const post = async (data) => {
   try {
      const response = await axios.post('/account/update_account', {
         data,
      });
      return {
         success: true,
         data: response,
      };
   } catch (err) {
      const errors = errorHandler(err);

      return {
         success: false,
         errors,
      };
   }
};

export default {
   get,
   post,
};
