import axios from '../../services/axiosInstance';
import parsePriceToNumber from '../../services/parsePriceToNumber';
import errorHandler from '../error/handler';

const storeId = process.env.NEXT_PUBLIC_STORE_ID;
const get = async (cardBrand, cardType, total, issuer, category) => {
   total = parsePriceToNumber(total);

   try {
      const response = await axios.get(
         `/card_promos?box=${cardBrand}&type=${cardType}&price=${total}&issuer=${issuer}&category=${category}&code=${storeId}`,
      );

      return {
         success: true,
         data: response.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};
const getAllPromos = async (storeID, productId) => {
   try {
      const response = await axios.get(`/card_promos/all?product_id=${productId}&code=${storeID}`, {
         code: storeID,
         product_id: productId,
      });

      return {
         success: true,
         data: response.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

export default {
   get,
   getAllPromos,
};
