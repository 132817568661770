import axios from '../../services/axiosInstance';

// eslint-disable-next-line camelcase
const post = async (email, code, eventId) => {
   try {
      const response = await axios.post('/newsletters', {
         email,
         code,
         eventId,
      });
      return {
         success: true,
         data: response,
      };
   } catch (err) {
      return {
         success: false,
         error: err.response.data.message || err.response.data.error,
      };
   }
};
const get = async (storeId) => {
   try {
      const response = await axios.get(`/newsletters?code=${storeId}`);
      return {
         success: true,
         data: response.data,
      };
   } catch (err) {
      return {
         success: false,
         error: err?.response?.data?.message,
      };
   }
};

export default {
   get,
   post,
};
