import React from 'react';
import cn from 'classnames';
import styles from './spinner.module.scss';

export default function Spinner({ className, isLarge, isData }) {
   const classNames = cn({
      [styles.loader]: true,
      [className]: true,
      [styles.loaderLarge]: isLarge,
      [styles.loadingData]: isData,
   });
   return <div className={classNames} />;
}
