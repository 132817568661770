/* eslint-disable camelcase */
import axios from '../../services/axiosInstance';
import errorHandler from '../error/handler';

const get = async (email) => {
   try {
      const response = await axios.get(
         `${process.env.NEXT_PUBLIC_API_URL}/account/user_data?email=${email}`,
      );
      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

export default {
   get,
};
