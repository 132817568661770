import axios from '../../services/axiosInstance';

const getInfo = async () => {
   try {
      const response = await axios.get('/streaming/channels', {
         headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
         },
      });
      return response.data.data;
   } catch (error) {
      return {
         hasError: true,
      };
   }
};

const saveUserInLottery = async (subscriber) => {
   const response = await axios.post('/streaming/channels/add_viewer_to_lottery', subscriber);
   return response.data.data;
};
const banUserFromChat = async (email) => {
   const response = await axios.post('/streaming/channels/bann_viewer', { subscriber: { email } });
   return response.data.data;
};
const isBanned = async (email) => {
   const response = await axios.post('/streaming/channels/check_viewer_is_banned', {
      subscriber: { email },
   });
   return response.data.data;
};
export default {
   getInfo,
   banUserFromChat,
   saveUserInLottery,
   isBanned,
};
