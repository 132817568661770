import axios from '../../services/axiosInstance';
import errorHandler from '../error/handler';

const addProductReview = async (id, { title, rating, comment }) => {
   try {
      const response = await axios.post(`/products/${id}/add_review`, {
         title,
         rating,
         review: comment,
      });
      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const errors = errorHandler(err);
      return {
         success: false,
         errors,
      };
   }
};

export default {
   addProductReview,
};
